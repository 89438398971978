import React from 'react';
import './Homeslider.css';

const HomeSlider = () => {
    return (

        <>
            <div>

            </div>
            <div className="slider-container ">
                <div className="slider-row">
                    <div className="slider-column">
                        <div className="slider-card">
                            <div className="slider-image">
                                <img src="http://anpsthemes.com/constructo-new-demos/6/wp-content/uploads/2014/11/image1.jpg" alt="Project Planning" />
                            </div>
                            <div className="slider-content">
                                <div className='d-flex gap-5'>
                                    <div className="slider-icon pt-2"><i className="fa fa-object-group p-3 shadow bg-warning rounded" style={{ color: '#736200' }}></i></div>
                                    <div>
                                        <div className="slider-title">THE PROJECT PLANNING</div>
                                        <div className="slider-divider  bg-warning"></div>
                                    </div>
                                </div>
                                <div className="slider-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas scelerisque lacinia vestibulum.</div>
                            </div>
                        </div>
                    </div>
                    <div className="slider-column">
                        <div className="slider-card">
                            <div className="slider-image">
                                <img src="http://anpsthemes.com/constructo-new-demos/6/wp-content/uploads/2014/11/image2.jpg" alt="Construction" />
                            </div>
                            <div className="slider-content">
                                <div className='d-flex gap-5'>
                                    <div className="slider-icon pt-2"><i className="fa fa-home p-3 shadow bg-warning rounded" style={{ color: '#736200' }}></i></div>
                                    <div>
                                        <div className="slider-title">THE CONSTRUCTION</div>
                                        <div className="slider-divider  bg-warning"></div>
                                    </div>
                                </div>
                                <div className="slider-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas scelerisque lacinia vestibulum.</div>
                            </div>
                        </div>
                    </div>
                    <div className="slider-column">
                        <div className="slider-card">
                            <div className="slider-image">
                                <img src="http://anpsthemes.com/constructo-new-demos/6/wp-content/uploads/2014/11/image3.jpg" alt="Finishing Work" />
                            </div>
                            <div className="slider-content">
                                <div className='d-flex gap-5'>
                                    <div className="slider-icon pt-2"><i className="fa fa-wrench p-3 shadow bg-warning rounded" style={{ color: '#736200' }}></i></div>
                                    <div>
                                        <div className="slider-title">THE FINISHING WORK</div>
                                        <div className="slider-divider  bg-warning"></div>
                                    </div>
                                </div>
                                <div className="slider-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas scelerisque lacinia vestibulum.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HomeSlider;
