import logo from './logo.svg';
import './App.css';
// import Navbar from './components/Navbar';
// import Header from './components/header/Header';
import Footer from './components/Footer/Footer';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/home/Home';
import Contact from './components/contact-us/Contact';
import Project from './components/All_Projects/Project';
import About from './components/About us/About';
import Blog from './components/Blog/Blog';
import Shop from './components/Shop/Shop';
import What_we_do from './components/What-we-do/What_we_do';
function App() {
  return (
    <BrowserRouter>
     
      <Routes>
         <Route path='/' element={<Home/>}/>
         <Route path='/contact-us' element={<Contact/>}/>
         <Route path='/project' element={<Project/>}/>
         <Route path='/about' element={<About/>}/>
         <Route path='/blog' element={<Blog/>}/>
         <Route path='/shop' element={<Shop/>}/>
         <Route path='/what-we-do' element={<What_we_do/>}/>
      </Routes>
     
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
