import React from 'react'
import './All_Projects.css'
const All_Projects = () => {
  return (
    <div>
      <div className='text-center pt-5 pb-5'>
        <div className='pb-3 fs-5'>
          TOP QUALITY WITH PERSONAL ATTENTION TO YOUR NEEDS <br />
          EXPLORE ALL OUR PROJECTS
        </div>
        <button className="btn-1 ps-5 pt-2 pb-2 pe-5 bg-warning " style={{ fontFamily: "'Roboto', sans-serif", borderStyle: 'none' }}>All PROJECTS</button>

      </div>
      <div className='bg-light all-projects-section ' style={{ paddingTop: "6rem" }}>
        <div>
          <img className='img-big' src="image/theguy.png" alt="" />
        </div>
        <div className=' all_projects_cards'>
          <div className='all_projects_cards11'>
            <div >
              <i className='fs-2 fa fa-home  p-4 bg-white text-warning'></i>
              <span className='ps-3'>CONSTRUCTION</span>
            </div>
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusamus fugiat vel laudantium</p>
          </div>
          <div className='all_projects_cards11'>
            <div>
              <i className='fs-2 fa fa-suitcase  p-4 bg-white text-warning'></i>
              <span className='ps-3'>CONSULTING</span>
            </div>
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusamus fugiat vel laudantium</p>

          </div>
          <div className='all_projects_cards11'>
            <div>
              <i className='fs-2 fa fa-wrench  p-4 bg-white text-warning'></i>
              <span className='ps-3'>RENOVATIONS</span>
            </div>
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusamus fugiat vel laudantium</p>

          </div>
          <div className='all_projects_cards11'>
            <div>
              <i className='fs-2 fa fa-building  p-4 bg-white text-warning'></i>
              <span className='ps-3'>ARCHITECTURE</span>
            </div>
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusamus fugiat vel laudantium</p>
          </div>

        </div>

      </div>
    </div>
  )
}

export default All_Projects
