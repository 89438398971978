import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import './Footer.css'
class Footer extends Component {
    render() {
        const shapeStyle = {
            visibility: "visible",
            animationDuration: "1s",
            animationDelay: "0.3s",
            animationName: "fadeInLeft",
        };

        return (
            <div className="footer mt-5">
                {/*#263238 */}
                <div
                    className="footer-top-wrap  mt-0"
                    style={{
                        paddingTop: "84px",
                    }}
                >
                    <div className="container">
                        <div className="footer-widgets-wrap">
                            <div className="row">
                                <div className="col-lg-5">
                                    <div className="footer-widget">
                                        <div className="footer-about">
                                            <div className="footer-logo logo pb-3">
                                               
                                                <img width={"290px"}
                                                    className="logo-sticky"
                                                    alt="Site logo"
                                                    src="https://construction2.indoredigital.com/images/IDLogo.png"
                                                />
                                            </div>
                                            <div className="footer-text mt-4 w-75">
                                                <p style={{ fontWeight: "700" }}>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam turpis quam, sodales in ante sagittis, varius efficitur mauris.
                                                </p>

                                            </div>
                                            <div className="footer-social-icons pt-3">
                                                <a
                                                    href=""
                                                    target="_blank"
                                                >
                                                    <i class="bi bi-instagram ps-2 pe-2  me-3 btn i-2"></i>
                                                </a>
                                                <a
                                                    href=""
                                                    target="_blank"
                                                >
                                                    <i class="bi bi-facebook ps-2 pe-2  me-3 btn i-1"></i>
                                                </a>
                                                <a
                                                    href=""
                                                    target="_blank"
                                                >
                                                    <i class="bi bi-twitter ps-2 pe-2  me-3 btn i-1"></i>
                                                </a>
                                                <a href="#" target="_blank">
                                                    <i class="bi bi-linkedin ps-2 pe-2  me-3 btn i-1"></i>
                                                </a>
                                                <a href="#" target="_blank">
                                                    <i class="bi bi-youtube ps-2 pe-2  me-5 btn i-3"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="footer-widget">
                                        <h3
                                            className="fw-title pb-4  "
                                            style={{ fontWeight: "600" }}
                                        >
                                            Useful Links
                                        </h3>
                                        <ul
                                            className="list-wrap"
                                            style={{ marginLeft: "px", fontWeight: "600" }}
                                        >
                                            <p className="pt1">
                                                <NavLink
                                                    to="/"
                                                    className="text-reset move-footer text-decoration-none"
                                                >
                                                    Home
                                                </NavLink>
                                            </p>
                                            <p className="pt-3">
                                                <NavLink
                                                    to="/about"
                                                    className="text-reset move-footer text-decoration-none"
                                                >
                                                     About Us
                                                </NavLink>
                                            </p>
                                            <p className="pt-3">
                                                <NavLink
                                                    to="/project"
                                                    className="text-reset move-footer text-decoration-none"
                                                >
                                                     Projects
                                                </NavLink>
                                            </p>
                                            <p className="pt-3">
                                                <NavLink
                                                    to="/blog"
                                                    className="text-reset move-footer text-decoration-none"
                                                >
                                                    Blogs
                                                </NavLink>
                                            </p>
                                           
                                           
                                            <p className="pt-2 pb-4">
                                                <NavLink
                                                    to="/contact-us"
                                                    className="text-reset move-footer text-decoration-none"
                                                >
                                                    Contact Us
                                                </NavLink>
                                            </p>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="footer-widget">
                                        <h3 className="fw-title ">Contacts</h3>
                                        <ul
                                            className="list-wrap pt-4"
                                            style={{  fontWeight: "600" }}
                                        >

                                            <div className="pb-3 pt-3">
                                                GLs Avenue 51 sec-92 , indore ,MP
                                            </div>
                                            <div className="pb-3">
                                                Sun - Fri: 8.00am - 6.00pm
                                            </div>
                                            <div className="pb-3">
                                                {" "}
                                                +91-9876543210
                                            </div>
                                            <div className="pb-5">
                                                indoredigital@gmail.com
                                            </div>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div
                    class="copyright-wrap p-4 text-center"
                    style={{
                        background: "#162842",
                        color: "black",
                        borderTop: "1px solid #a068f4",
                        alignItems: "center",
                    }}
                >
                    <div class="container text-light" style={{ fontWeight: "600" }}>
                        Copyright © 2024 Indore Digital Company. All Rights Reserved.
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;
