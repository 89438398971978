import React from 'react';
import './About.css';  // Import the CSS file

const About2 = () => {
    return (
        <div>
            <div className="vc_row wpb_row vc_row-fluid vc_custom_1418301066256">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                            <div className="row">
                                <div className="col-lg-3 col-sm-6">
                                    <div className="team team-hover card">
                                        <header>
                                            <img className='emp-img' src="image/ceo1.jpg" alt="Steve Boss" />
                                            <div className="hover">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent mattis aliquet purus non elementum. Nam quis vulputate enim, congue ullamcorper risus.
                                            </div>
                                        </header>
                                        <h2>Steve Boss</h2>
                                        <em>CEO</em>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="team team-hover card">
                                        <header>
                                            <img className='emp-img' src="image/sales.jpg" alt="Paul Sold" />
                                            <div className="hover">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent mattis aliquet purus non elementum. Nam quis vulputate enim, congue ullamcorper risus.
                                            </div>
                                        </header>
                                        <h2>Paul Sold</h2>
                                        <em>Sales</em>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="team team-hover card">
                                        <header>
                                            <img className='emp-img' src="image/project-manager.jpg" alt="George Big" />
                                            <div className="hover">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent mattis aliquet purus non elementum. Nam quis vulputate enim, congue ullamcorper risus.
                                            </div>
                                        </header>
                                        <h2>George Big</h2>
                                        <em>Project Manager</em>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="team team-hover card">
                                        <header>
                                            <img className='emp-img' src="image/quality-manager.jpg" alt="John Doe" />
                                            <div className="hover">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent mattis aliquet purus non elementum. Nam quis vulputate enim, congue ullamcorper risus.
                                            </div>
                                        </header>
                                        <h2>John Doe</h2>
                                        <em>Quality Manager</em>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About2;
