import React from 'react';
import './About.css';  // Import the CSS file

const About3 = () => {
    return (
        <div className="background-img  m-auto bg-light  ">
            <div className="elementor-element elementor-element-ea2457b e-con-full elementor-hidden-mobile e-flex e-con e-child" data-id="ea2457b" data-element_type="container" data-settings='{"content_width":"full"}'>
            </div>
            <div className="about3-text" data-id="1a2b0f0" data-element_type="container" data-settings='{"content_width":"full","background_background":"classic"}'>
                <div className="elementor-element elementor-element-724d4f6 elementor-widget elementor-widget-heading" data-id="724d4f6" data-element_type="widget" data-widget_type="heading.default">
                    <div className="elementor-widget-container">
                        <h2 className="elementor-heading-title elementor-size-default">About <span style={{color: 'red'}}>Us</span></h2>
                    </div>
                </div>
                <div className="elementor-element elementor-element-4161bba elementor-widget__width-initial elementor-widget elementor-widget-text-editor" data-id="4161bba" data-element_type="widget" data-widget_type="text-editor.default">
                    <div className="elementor-widget-container">
                      
                        <p>At <b>[Company Name] Constructions</b>, our story is one of passion, innovation, and unwavering commitment to our clients’ dreams. Based in the vibrant city of <b>Indore, Madhya Pradesh</b>, we are a collective of skilled architects, talented engineers, and dedicated contractors who breathe life into your visions.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About3;
