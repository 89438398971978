import React from 'react'

export default function FAQ_about() {
  return (
    <div className=''>
      <div className="secondcontainer">
        <div className=' bg-light pt-5 pb-5 '>

        <div className="faq-section  m-auto">
          <button className="btn-1 ps-5 pt-2 pb-2 pe-5 bg-warning" style={{ fontFamily: "'Roboto', sans-serif", borderStyle: 'none' }}>FAQ</button>
           
          <p className='fs-2' style={{ fontWeight: '700', fontFamily: "'Roboto', sans-serif" }}>Frequently Asked Question.</p>

          <div className='fs-5' style={{ fontFamily: "'Roboto', sans-serif" }}>
          Comprehensive Guide to Construction: Frequently Asked Questions on Project Timelines, Contractor Selection, Permits, Costs, Safety, Compliance, and Sustainable Practices
          </div>
        </div>
        </div>

        <div className="FAQ_section" >
          <img className="rounded faq_img" src="image/kalu1.png" />
          <div className="accordion  faq_text" id="accordionExample" style={{zIndex:"1"}}>
            <div class="accordion-item border-light shadow">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" style={{ fontFamily: "'Roboto', sans-serif" }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  1. What is the typical timeline for a construction project?
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body" style={{ fontFamily: "'Roboto', sans-serif" }}>
                  The timeline for a construction project can vary widely depending on the project's scope, size, complexity, and weather conditions. Small projects like home renovations may take a few weeks to a few months, while large-scale commercial buildings can take several months to a few years to complete.
                </div>
              </div>
            </div>
            <div class="accordion-item mt-4 border-light border-top shadow">
              <h2 class="accordion-header" id="headingTwo">
                <button style={{ fontFamily: "'Roboto', sans-serif" }} class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                2. How do I choose a reliable contractor?
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div class="accordion-body" style={{ fontFamily: "'Roboto', sans-serif" }}>
                To choose a reliable contractor, research their experience, check their references, review their past work, and read reviews from previous clients. It’s also beneficial to get multiple quotes and compare them.
                </div>
              </div>
            </div>
            <div class="accordion-item mt-4 border-light border-top shadow">
              <h2 class="accordion-header" id="headingThree">
                <button class="accordion-button collapsed" style={{ fontFamily: "'Roboto', sans-serif" }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                3. What permits are needed for a construction project?
                </button>
              </h2>
              <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div class="accordion-body" style={{ fontFamily: "'Roboto', sans-serif" }}>
                Permit requirements vary by location and the type of construction. Common permits include building permits, electrical permits, plumbing permits, and mechanical permits. Always check with your local building department to determine what is needed for your specific project.
                 </div>
              </div>
            </div>
            <div class="accordion-item mt-4 border-light border-top shadow">
              <h2 class="accordion-header" id="headingfour">
                <button class="accordion-button collapsed" style={{ fontFamily: "'Roboto', sans-serif" }} type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                4. How is the cost of a construction project estimated?
                </button>
              </h2>
              <div id="collapsefour" class="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                <div class="accordion-body" style={{ fontFamily: "'Roboto', sans-serif" }}>
                The cost of a construction project is estimated based on factors like the design, materials, labor, permits, and unforeseen expenses. Contractors typically provide a detailed estimate after reviewing the project scope and conducting a site visit.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
