import React from 'react'
import FAQ_about from './FAQabout.jsx'
import All_Services from '../all_services/All_Services'
import All_Projects from '../All_Projects/All_Projects'
import Our_news from '../Our_news/Our_news'
import Header from '../header/Header.jsx'
import Hello from './hell.jsx'
import Contact_home from '../contact-us/Contact_home.jsx'
import Slider_Section from './Slidersection.jsx'
import HomeSlider from './Home_slider.jsx'

const Home = () => {
  return (
    <div className='' >
      <Header/>
      <Slider_Section/>
      <Hello/>
      <HomeSlider/>
      <All_Services/>
      <All_Projects/>
      <Our_news/>
      <FAQ_about />
      <Contact_home/>
    </div>
  )
}

export default Home
