import React from 'react';
import { Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import './Homeslider.css';
import { Swiper, SwiperSlide } from 'swiper/react';

export default function Slider_Section() {
  return (
    <div className='slider-section1' style={{ marginTop: '-2.4rem' }}>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide className='home_sliders2'>
          <img className='swiper1' src="image/slider112.jpg" alt="Slider Image 1" />
          <img className='swiper2' src="image/slider3-min.jpg" alt="Slider Image 2" />
        </SwiperSlide>

        <SwiperSlide className='home_sliders2'>
          <img className='swiper1' src="image/slider113.jpg" alt="Slider Image 3" />
          <img className='swiper2' src="image/slider2-min.jpg" alt="Slider Image 4" />
        </SwiperSlide>

        <SwiperSlide className='home_sliders2'>
          <img className='swiper1' src="image/slider111.jpg" alt="Slider Image 5" />
          <img className='swiper2' src="image/slider11.png" alt="Slider Image 6" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
