import React from 'react';
import { useIntersectionObserver } from './useIntersectionObserver'; // Adjust the import path as necessary
import './All_services.css'
const portfolioItems = [
  {
    title: "Modern Building",
    imageUrl: "http://anpsthemes.com/constructo-new-demos/6/wp-content/uploads/2014/12/image1.jpg",
    link: "",
  },
  {
    title: "Solar Power",
    imageUrl: "http://anpsthemes.com/constructo-new-demos/6/wp-content/uploads/2014/12/image2.jpg",
    link: "",
  },
  {
    title: "The Residence",
    imageUrl: "http://anpsthemes.com/constructo-new-demos/6/wp-content/uploads/2014/12/image3.jpg",
    link: "",
  },
  
];

const PortfolioItem = ({ title, imageUrl, link, index, isVisible }) => (
  <div
    className={`recent-portfolio__item ${isVisible ? 'visible' : ''}`}
    style={{ animationDelay: `${index * 0.2}s` }}
  >
    <div className="recent-portfolio__image">
      <img src={imageUrl} alt={title} className="attachment-full size-full wp-post-image" />
    </div>
    <div className="recent-portfolio__wrap">
      <h2 className="recent-portfolio__title">{title}</h2>
      <a href={link} className="recent-portfolio__link btn style-1">View project</a>
    </div>
  </div>
);

const All_Services = () => {
  const [containerRef, isVisible] = useIntersectionObserver({
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  });

  return (
    <div
      ref={containerRef}
      className="vc_row wpb_row vc_row-fluid vc_custom_1496747747888 vc_row-no-padding bg-light"
    >
   <div className='text-center'>

    <button className="btn-1 ps-5 pt-2 pb-2 pe-5 bg-warning " style={{ fontFamily: "'Roboto', sans-serif", borderStyle: 'none' }}>All Services</button>
   </div>

      <div className="wpb_column vc_column_container vc_col-sm-12">
        <div className="vc_column-inner">

          <div className="wpb_wrapper">
            <div className="recent-portfolio bg-white pb-4 recent-portfolio--modern-2 recent-portfolio--col-4 recent-portfolio--mobile-2">
              {portfolioItems.map((item, index) => (
                <PortfolioItem
                  key={index}
                  title={item.title}
                  imageUrl={item.imageUrl}
                  link={item.link}
                  index={index}
                  isVisible={isVisible}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default All_Services;
