import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useIntersectionObserver } from '../all_services/useIntersectionObserver'; // Adjust the import path as necessary
import '../all_services/All_services.css';
import './Project.css'
import All_Projects from './All_Projects';
import Header from '../header/Header';

const portfolioItems = [
    {
        title: "Modern Building",
        imageUrl: "http://anpsthemes.com/constructo-new-demos/6/wp-content/uploads/2014/12/image1.jpg",
        link: "",
    },
    {
        title: "Solar Power",
        imageUrl: "http://anpsthemes.com/constructo-new-demos/6/wp-content/uploads/2014/12/image2.jpg",
        link: "",
    },
    {
        title: "The Residence",
        imageUrl: "http://anpsthemes.com/constructo-new-demos/6/wp-content/uploads/2014/12/image3.jpg",
        link: "",
    },
    {
        title: "Modern Vacation",
        imageUrl: "http://anpsthemes.com/constructo-new-demos/6/wp-content/uploads/2014/11/image4.jpg",
        link: "",
    },
    {
        title: "Modern Building",
        imageUrl: "http://anpsthemes.com/constructo-new-demos/6/wp-content/uploads/2014/12/image1.jpg",
        link: "",
    },
    {
        title: "Solar Power",
        imageUrl: "http://anpsthemes.com/constructo-new-demos/6/wp-content/uploads/2014/12/image2.jpg",
        link: "",
    },
    {
        title: "The Residence",
        imageUrl: "http://anpsthemes.com/constructo-new-demos/6/wp-content/uploads/2014/12/image3.jpg",
        link: "",
    },
    {
        title: "Modern Vacation",
        imageUrl: "http://anpsthemes.com/constructo-new-demos/6/wp-content/uploads/2014/11/image4.jpg",
        link: "",
    },
];

const PortfolioItem = ({ title, imageUrl, link, index, isVisible }) => (
    <div
        className={`recent-portfolio__item ${isVisible ? 'visible' : ''}`}
        style={{ animationDelay: `${index * 0.2}s` }}
    >
        <div className="recent-portfolio__image wwhelllll position-relative">
            <img src={imageUrl} alt={title} className="" />
        </div>

    </div>
);

const Project = () => {
    const [containerRef, isVisible] = useIntersectionObserver({
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
    });
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, []);

    return (
        <>
        <Header/>
            <div className='text-center header_head' style={{ backgroundColor: '#d9f2f7' }}>
                <div className='fs-4' style={{ fontWeight: "700", fontFamily: "'Roboto', sans-serif" }}>Projects</div>
                <div className='d-flex justify-content-center'>
                    <NavLink to='/' style={{ fontFamily: "'Roboto', sans-serif" }} className='text-decoration-none text-dark'>
                        <span className='bi bi-house-door-fill text-info'></span> Home
                    </NavLink>
                    <div style={{ fontFamily: "'Roboto', sans-serif" }}>
                        <span className='bi bi-arrow-right-short text-info ps-1'></span> Projects
                    </div>
                </div>
            </div>
            <div
                ref={containerRef}
                className="vc_row wpb_row vc_row-fluid vc_custom_1496747747888 vc_row-no-padding bg-light m-0"
            >
                <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                            <div className="recent-portfolio bg-white pb-4 recent-portfolio--modern-2 recent-portfolio--col-4 recent-portfolio--mobile-2">
                                {portfolioItems.map((item, index) => (
                                    <PortfolioItem
                                        key={index}
                                        title={item.title}
                                        imageUrl={item.imageUrl}
                                        link={item.link}
                                        index={index}
                                        isVisible={isVisible}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className='bg-light all-projects-section ' style={{ paddingTop: "6rem" }}>
                    <div>
                        <img className='img-big' src="image/theguy.png" alt="" />
                    </div>
                    <div className='  all_projects_cards'>
                        <div className='all_projects_cards11'>
                            <div >
                                <i className='fs-2 fa fa-home  p-4 bg-white text-warning'></i>
                                <span className='ps-3'>CONSTRUCTION</span>
                            </div>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusamus fugiat vel laudantium</p>
                        </div>
                        <div className='all_projects_cards11'>
                            <div>
                                <i className='fs-2 fa fa-suitcase  p-4 bg-white text-warning'></i>
                                <span className='ps-3'>CONSULTING</span>
                            </div>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusamus fugiat vel laudantium</p>

                        </div>
                        <div className='all_projects_cards11'>
                            <div>
                                <i className='fs-2 fa fa-wrench  p-4 bg-white text-warning'></i>
                                <span className='ps-3'>RENOVATIONS</span>
                            </div>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusamus fugiat vel laudantium</p>

                        </div>
                        <div className='all_projects_cards11'>
                            <div>
                                <i className='fs-2 fa fa-building  p-4 bg-white text-warning'></i>
                                <span className='ps-3'>ARCHITECTURE</span>
                            </div>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusamus fugiat vel laudantium</p>
                        </div>

                    </div>

                </div>
            </div>
        </>
    );
};

export default Project;
