import React, { useEffect } from 'react';
import './What_we_do.css';
import { Link, NavLink } from 'react-router-dom';
import Header from '../header/Header';

const What_we_do = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, []);
    return (
        <div>
            <Header />
            <div className='text-center header_head bg-light'>
                <div className='fs-4' style={{ fontWeight: "700", fontFamily: "'Roboto', sans-serif" }}>WHAT WE DO</div>
                <div className='d-flex justify-content-center'>
                    <NavLink to='/' style={{ fontFamily: "'Roboto', sans-serif" }} className='text-decoration-none text-dark'>
                        <span className='bi bi-house-door-fill text-info'></span> Home
                    </NavLink>
                    <div style={{ fontFamily: "'Roboto', sans-serif" }}>
                        <span className='bi bi-arrow-right-short text-info ps-1'></span>WHAT WE DO
                    </div>
                </div>
            </div>
            <div>
                <div className='bg-white about-data' style={{ paddingTop: "6rem" }}>
                    <div className='col_3'>
                        <aside className="sidebar bg-white">
                            <ul>
                                <li id="nav_menu-3" className="widget-container widget_nav_menu">
                                    <div className="menu-side-menu-container">
                                        <ul id="menu-side-menu" className="menu">
                                            <li id="menu-item-275" className="menu-item1 menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-9 current_page_item menu-item-275"><Link className='d-flex justify-content-between' href=""><span>ALL SERVICES</span><i class="bi bi-arrow-right-short"></i></Link></li>
                                            <li id="menu-item-274" className="menu-item1 menu-item-type-post_type menu-item-object-page menu-item-274"><Link className='d-flex justify-content-between'  href=""><span>CONSTRUCTION</span> <i class="bi bi-arrow-right-short"></i></Link></li>
                                            <li id="menu-item-273" className="menu-item1 menu-item-type-post_type menu-item-object-page menu-item-273"><Link className='d-flex justify-content-between'  href=""><span>RENOVATIONS</span> <i class="bi bi-arrow-right-short"></i></Link></li>
                                            <li id="menu-item-271" className="menu-item1 menu-item-type-post_type menu-item-object-page menu-item-271"><Link className='d-flex justify-content-between'  href=""><span>BIG PROJECTS</span> <i class="bi bi-arrow-right-short"></i></Link></li>
                                            <li id="menu-item-272" className="menu-item1 menu-item-type-post_type menu-item-object-page menu-item-272"><Link  className='d-flex justify-content-between' href=""><span>CONSULTING</span> <i class="bi bi-arrow-right-short"></i></Link></li>
                                            <li id="menu-item-270" className="menu-item1 menu-item-type-post_type menu-item-object-page menu-item-270"><Link className='d-flex justify-content-between'  href=""><span>ARCHITECTURE</span> <i class="bi bi-arrow-right-short"></i></Link></li>
                                            <li id="menu-item-269" className="menu-item1 menu-item-type-post_type menu-item-object-page menu-item-269"><Link className='d-flex justify-content-between'  href=""><span>CONCRETE TRANSPORT </span><i class="bi bi-arrow-right-short"></i></Link></li>
                                        </ul>
                                    </div>
                                </li>
                                <li id="anpsdownload-2" className="widget-container widget_anpsdownload">
                                    <h3 className="widget-title pt-4 pb-4">Get your brochures</h3>
                                    <div className="anps_download border ps-4 pe-4 pt-1 pb-1 mb-3">
                                        <Link href="" target="_blank">
                                            <span className="anps_download_icon" style={{ backgroundColor: "#e0e0e0" }}><i className="fa file-pdf-o" style={{ color: "#808080" }}></i></span>
                                            <span className="download-title">Download file</span>
                                            <div className="clearfix"></div>
                                        </Link>
                                    </div>
                                </li>
                                <li id="anpsdownload-3" className="widget-container widget_anpsdownload">
                                    <div className="anps_download border ps-4 pe-4 pt-1 pb-1 mb-3">
                                        <Link href="" target="_blank">
                                            <span className="anps_download_icon" style={{ backgroundColor: "#e0e0e0" }}><i className="fa file-word-o" style={{ color: "#808080" }}></i></span>
                                            <span className="download-title">Download file</span>
                                            <div className="clearfix"></div>
                                        </Link>
                                    </div>
                                </li>
                                <li id="text-6 " className="widget-container pt-4 pb-4 ps-1 widget_text">
                                    <h3 className="widget-title">Our office</h3>
                                    <div className="textwidget"></div>
                                </li>
                                <li id="anpstext-5" className="widget-container widget_anpstext ">
                                    <span className="anpstext-arrow"></span>
                                    <span className="fa fa-map-marker pe-4 text-warning "></span>
                                    <span className="anpstext-desc pt-5 text-secondary">Transport ltd. inc.<br /> 300 Pennsylvania Ave NW, Washington</span>
                                </li>
                                <li id="anpstext-6" className="widget-container widget_anpstext">
                                    <span className="anpstext-arrow"></span>
                                    <span className="fa fa-phone pe-4 text-warning"></span>
                                    <span className="anpstext-desc pt-4 text-secondary">Phone: +386 40 112 555</span>
                                </li>
                                <li id="anpstext-7" className="widget-container widget_anpstext">
                                    <span className="anpstext-arrow"></span>
                                    <span className="fa fa-envelope text-warning pe-4"></span>
                                    <span className="anpstext-desc pt-4 text-secondary">Mail: info@domain.com</span>
                                </li>
                            </ul>
                        </aside>
                    </div>
                    <div className='about-data-2 col_9'>
                        <div className='about-data-child2'>
                            <img src="image/ourNews2.jpg" alt="" />
                            <div className=' p-3 '>
                                <h6 className='title'>FROM IDEA TO REALISATION</h6>
                                <div className='text1'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum explicabo, numquam enim dolor quas quo eaque.</div>
                                <h5 className='pt-4 text-warning read-more'>READ MORE</h5>
                            </div>
                        </div>
                        <div className='about-data-child2'>
                            <img src="image/ourNews1.jpg" alt="" />
                            <div className=' p-3'>
                                <h6 className='title'>MAKE YOUR HOME YOUR PARADISE</h6>
                                <div className='text1'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum explicabo, numquam enim dolor quas quo eaque.</div>
                                <h5 className='pt-4 text-warning read-more'>READ MORE</h5>
                            </div>
                        </div>
                        <div className='about-data-child2'>
                            <img src="image/ourNews3.jpg" alt="" />
                            <div className=' p-3'>
                                <h6 className='title'>HOME RENOVATIONS WITH NO FUSS</h6>
                                <div className='text1'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum explicabo, numquam enim dolor quas quo eaque.</div>
                                <h5 className='pt-4 text-warning read-more'>READ MORE</h5>
                            </div>
                        </div>
                        <div className='about-data-child2'>
                            <img src="image/ourNews3.jpg" alt="" />
                            <div className=' p-3'>
                                <h6 className='title'>HOME RENOVATIONS WITH NO FUSS</h6>
                                <div className='text1'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum explicabo, numquam enim dolor quas quo eaque.</div>
                                <h5 className='pt-4 text-warning read-more'>READ MORE</h5>
                            </div>
                        </div>
                        <div className='about-data-child2'>
                            <img src="image/ourNews1.jpg" alt="" />
                            <div className=' p-3'>
                                <h6 className='title'>MAKE YOUR HOME YOUR PARADISE</h6>
                                <div className='text1'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum explicabo, numquam enim dolor quas quo eaque.</div>
                                <h5 className='pt-4 text-warning read-more'>READ MORE</h5>
                            </div>
                        </div>
                        <div className='about-data-child2'>
                            <img src="image/ourNews3.jpg" alt="" />
                            <div className=' p-3'>
                                <h6 className='title'>HOME RENOVATIONS WITH NO FUSS</h6>
                                <div className='text1'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum explicabo, numquam enim dolor quas quo eaque.</div>
                                <h5 className='pt-4 text-warning read-more'>READ MORE</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default What_we_do;
