import React, { useEffect } from 'react';
import './Shop.css';
import { NavLink } from 'react-router-dom';
import Header from '../header/Header';

const Shop = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, []);

    const products = [
        { name: 'brush', price: '$20.00', rating: 4.5, image: 'image/shop1.jpg' },
        { name: 'Chain saw', price: '$30.00', rating: 4.5, image: 'image/shop2.jpg' },
        { name: 'Color roller', price: '$24.00', rating: 4.5, image: 'image/shop3.jpg' },
        { name: 'Construction helmet', price: '$40.00', rating: 4.5, image: 'image/shop4.jpg' },
        { name: 'Cuttes', price: '$44.00', rating: 4.5, image: 'image/shop5.jpg' },
        { name: 'Drill set', price: '$26.00', rating: 4.5, image: 'image/shop6.jpg' },
        { name: 'Electric saw', price: '$27.00', rating: 4.5, image: 'image/shop7.jpg' },
        { name: 'Grass mower', price: '$20.00', rating: 4.5, image: 'image/shop8.jpg' },
        { name: 'Hammer', price: '$28.00', rating: 4.5, image: 'image/shop9.jpg' },
        { name: 'Jack hammer', price: '$20.00', rating: 4.5, image: 'image/shop10.jpg' },
        { name: 'Ladder', price: '$56.00', rating: 4.5, image: 'image/shop11.jpg' },
        { name: 'Measurement tape', price: '$27.00', rating: 4.5, image: 'image/shop12.jpg' },
   
    ];

    return (
        <>
        <Header/>
        <div className=' pb-5'>
            <div className='text-center header_head bg-light'>
                <div className='fs-4' style={{ fontWeight: "700", fontFamily: "'Roboto', sans-serif" }}>SHOP</div>
                <div className='d-flex justify-content-center'>
                    <NavLink to='/' style={{ fontFamily: "'Roboto', sans-serif" }} className='text-decoration-none text-dark'>
                        <span className='bi bi-house-door-fill text-info'></span> Home
                    </NavLink>
                    <div style={{ fontFamily: "'Roboto', sans-serif" }}>
                        <span className='bi bi-arrow-right-short text-info ps-1'></span>SHOP
                    </div>
                </div>
            </div>
            <div className='d-flex flex-wrap justify-content-center pt-5 shop-section'>
                {products.map((product, index) => (
                    <div key={index}>
                        <img src={product.image} alt={product.name} />
                        <div className='pt-4 p-3'>
                            <h6>{product.name}</h6>
                            <div className='d-flex justify-content-between'>
                                <span>{product.price}</span>
                                <span>
                                    {[...Array(5)].map((star, i) => (
                                        <i key={i} className={`bi ${i < Math.floor(product.rating) ? 'bi-star-fill' : (i < product.rating ? 'bi-star-half' : 'bi-star')} text-warning`}></i>
                                    ))}
                                </span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        </>
    );
};

export default Shop;
