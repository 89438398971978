import React, { useEffect } from 'react';
import './Blog.css';
import HomeSlider from '../home/Home_slider';
import { NavLink } from 'react-router-dom';
import Header from '../header/Header';

const Blog = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    const posts = [
        {
            img: "image/ourNews2.jpg",
            title: "FROM IDEA TO REALISATION",
            date: "DECEMBER 12, 2023",
            comments: "COMMENTS",
            excerpt: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum explicabo, numquam enim dolor quas quo eaque.",
        },
        {
            img: "image/ourNews1.jpg",
            title: "MAKE YOUR HOME YOUR PARADISE",
            date: "DECEMBER 12, 2023",
            comments: "1 COMMENTS",
            excerpt: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum explicabo, numquam enim dolor quas quo eaque.",
        },
        {
            img: "image/ourNews3.jpg",
            title: "HOME RENOVATIONS WITH NO FUSS",
            date: "DECEMBER 12, 2023",
            comments: "COMMENTS",
            excerpt: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum explicabo, numquam enim dolor quas quo eaque.",
        },
    ];

    const renderPosts = () => {
        return posts.map((post, index) => (
            <div key={index}>
                <img src={post.img} alt={post.title} />
                <div className='p-3'>
                    <h5 className='title'>{post.title}</h5>
                    <div className='pb-4 pt-2'>
                        <i className='bi bi-calendar3 text-warning'></i>
                        <span className='ps-2'>{post.date}</span>
                        <span className='ps-4'>
                            <i className='bi bi-chat-dots-fill text-warning'></i>
                            <span className='ps-2'>{post.comments}</span>
                        </span>
                    </div>
                    <div>{post.excerpt}</div>
                    <h5 className='pt-4 text-warning read-more'>READ MORE</h5>
                </div>
            </div>
        ));
    };

    return (
        <>
            <Header />
            <div className='text-center header_head  bg-light'>
                <div className='fs-4' style={{ fontWeight: "700", fontFamily: "'Roboto', sans-serif" }}>BLOG</div>
                <div className='d-flex justify-content-center'>
                    <NavLink to='/' style={{ fontFamily: "'Roboto', sans-serif" }} className='text-decoration-none text-dark'>
                        <span className='bi bi-house-door-fill text-info'></span> Home
                    </NavLink>
                    <div style={{ fontFamily: "'Roboto', sans-serif" }}>
                        <span className='bi bi-arrow-right-short text-info ps-1'></span>BLOG
                    </div>
                </div>
            </div>
            <div className='blog-blog'>
                <div className='blog-section'>
                    {renderPosts()}
                </div>
                <Sidebar />
            </div>
            <HomeSlider />
        </>
    );
};

const Sidebar = () => {
    return (
        <div className='siderbar-pai'>
            <aside className="sidebar">
                <ul>
                    <SearchWidget />
                    <RecentPostsWidget />
                    <RecentCommentsWidget />
                    <ArchivesWidget />
                    <CategoriesWidget />
                    <MetaWidget />
                </ul>
            </aside>
        </div>
    );
};

const SearchWidget = () => {
    return (
        <li id="search-2" className="widget-container widget_search">
            <form role="search" method="get" id="searchform" className="searchform" action="/">
                <div className='position-relative'>
                    <label className="screen-reader-text" htmlFor="s">Search for:</label>
                    <input type="text" name="s" id="s" placeholder="Search..." />
                    <button type="submit" className="fa fa-search position-absolute" style={{ right: '0', padding: '0.84rem' }} id="searchsubmit"></button>
                </div>
            </form>
        </li>
    );
};

const RecentPostsWidget = () => {
    return (
        <li id="recent-posts-2" className="widget-container widget_recent_entries">
            <h3 className="widget-title">Recent Posts</h3>
            <ul>
                <li><NavLink to=''>FROM IDEA TO REALISATION</NavLink></li>
                <li><NavLink to=''>HOME RENOVATIONS WITH NO FUSS</NavLink></li>
                <li><NavLink to=''>MAKE YOUR HOME YOUR PARADISE</NavLink></li>
                <li><NavLink to=''>GET YOUR NEW HOME LIKE A PRO</NavLink></li>
                <li><NavLink to=''>THE JOURNEY OF A BIG BUILD</NavLink></li>
            </ul>
        </li>
    );
};

const RecentCommentsWidget = () => {
    return (
        <li id="recent-comments-2" className="widget-container widget_recent_comments">
            <h3 className="widget-title">Recent Comments</h3>
            <ul id="recentcomments">
                <li className="recentcomments"><span className="comment-author-link">admin</span> on <a href="#">Brush</a></li>
                <li className="recentcomments"><span className="comment-author-link">admin</span> on <a href="#">FROM IDEA TO REALISATION</a></li>
                <li className="recentcomments"><span className="comment-author-link">admin</span> on <a href="#">FROM IDEA TO REALISATION</a></li>
                <li className="recentcomments"><span className="comment-author-link">admin</span> on <a href="#">GET YOUR NEW HOME LIKE A PRO</a></li>
                <li className="recentcomments"><span className="comment-author-link">admin</span> on <a href="#">FROM IDEA TO REALISATION</a></li>
            </ul>
        </li>
    );
};

const ArchivesWidget = () => {
    return (
        <li id="archives-2" className="widget-container widget_archive">
            <h3 className="widget-title">Archives</h3>
            <ul>
                <li><NavLink to=''>December 2014</NavLink></li>
                <li><NavLink to=''>November 2014</NavLink></li>
            </ul>
        </li>
    );
};

const CategoriesWidget = () => {
    return (
        <li id="categories-2" className="widget-container widget_categories">
            <h3 className="widget-title">Categories</h3>
            <ul>
                <li className="cat-item cat-item-8"><NavLink to=''>Big buildings</NavLink></li>
                <li className="cat-item cat-item-11"><NavLink to=''>Home</NavLink></li>
                <li className="cat-item cat-item-16"><NavLink to=''>Project</NavLink></li>
                <li className="cat-item cat-item-15"><NavLink to=''>Renovations</NavLink></li>
                <li className="cat-item cat-item-1"><NavLink to=''>Uncategorized</NavLink></li>
            </ul>
        </li>
    );
};

const MetaWidget = () => {
    return (
        <li id="meta-2" className="widget-container widget_meta">
            <h3 className="widget-title">Meta</h3>
            <ul>
                <li><NavLink to=''>Log in</NavLink></li>
                <li><NavLink to=''>Entries <abbr title="Really Simple Syndication">RSS</abbr></NavLink></li>
                <li><NavLink to=''>Comments <abbr title="Really Simple Syndication">RSS</abbr></NavLink></li>
                <li><NavLink to='' title="Powered by WordPress, state-of-the-art semantic personal publishing platform.">WordPress.org</NavLink></li>
            </ul>
        </li>
    );
};

export default Blog;
