import React from 'react';
import { useInView } from 'react-intersection-observer';
import './hell.css'; // Import CSS file for styling

const Hello = () => {
    const { ref, inView } = useInView({
        triggerOnce: true, // Only trigger once
        threshold: 0.5 // Trigger animation when 50% of the element is in view
    });

    return (
        <div className="hello-container  bg-light">
            <div className={`content-container ${inView ? 'animate' : ''}`} ref={ref}>
                <div className="heading-container">
                <button className="btn-1 fs-2  bg-warning hell-title " style={{ fontFamily: "'Roboto', sans-serif", borderStyle: 'none' }}>Let's Build <span className="highlight-text"> With Us</span></button>

                </div>
                <div className="text-container">
                    <p className='fw-medium text-success-emphasis'>
                        Now that you have reached so far scrolling, I would like this opportunity to discuss your home & building needs. Be it <span className="highlight-text"><a href="">construction</a></span>, designing, or planning, [Company Name] Constructions has got name as a <b>reputed construction company in Indore</b>. With a smile and dignity, we are happy to have built homes that offer comfort spaces. With such expertise and vision, we’re eager to hear about your ideas for your home. Please fill out the form, and we’ll soon be in touch to chat about making your dream home a reality.
                    </p>
                </div>
            </div>
            <div className={`image-container ${inView ? 'animate' : ''}`} ref={ref}>
                <img className="image-hell" loading="lazy" decoding="async" src="https://samasthiticonstructions.com/wp-content/uploads/2023/12/Copy-of-N11-e1703245958261-1024x974.png" alt="home constructed by a renowned construction company in Indore | best Home builder in Indore" />
            </div>
        </div>
    );
};

export default Hello;
