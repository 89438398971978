import React from 'react'
import './Our_news.css'
import {useIntersectionObserver} from '../all_services/useIntersectionObserver'
const Our_news = () => {
    
        const [containerRef, isVisible] = useIntersectionObserver({
          root: null,
          rootMargin: '0px',
          threshold: 0.1,
        });
    return (
        <div className='pt-5 pb-5' ref={containerRef}>
            <div className='text-center out-news-title' style={{paddingTop:"7rem",paddingBottom:"2rem"}}>
                <div className='fs-5'>OUR NEWS</div>

                <p className='line-news-title rounded'></p>

            </div>
            <div className='ournews-card'>
                <div>
                    <img src="image/ourNews2.jpg" alt="" />
                    <div className=' p-3 '>
                        <h5 className='title'>FROM IDEA TO REALISATION</h5>
                        <div className='pb-4 'style={{paddingTop:"2rem"}}>
                            <i className='bi bi-calendar3 text-warning'></i>
                            <span className='ps-2'>DECEMBER 12 ,2023</span>
                            <span className='ps-4'>
                                <i className='bi bi-chat-dots-fill text-warning'></i>
                                <span className='ps-2'>COMMENTS</span>
                            </span>
                        </div>
                        <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum explicabo, numquam enim dolor quas quo eaque. </div>
                        <h5 className='pt-4 text-warning read-more'>READ MORE</h5>
                    </div>
                </div>
                <div>
                    <img src="image/ourNews1.jpg" alt="" />
                    <div className=' p-3'>
                        <h5 className='title'>MAKE YOUR HOME YOUR PARADISE</h5>
                        <div className='pb-4 pt-2'>
                            <i  className='bi bi-calendar3 text-warning'></i>
                            <span className='ps-2'>DECEMBER 12 ,2023</span>
                            <span className='ps-4'>
                                <i className='bi bi-chat-dots-fill text-warning'></i>
                                <span className='ps-2'>1 COMMENTS</span>
                            </span>
                        </div>
                        <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum explicabo, numquam enim dolor quas quo eaque. </div>
                        <h5 className='pt-4 text-warning read-more'>READ MORE</h5>
                    </div>
                </div>
                <div>
                    <img src="image/ourNews3.jpg" alt="" />
                    <div className=' p-3'>
                        <h5 className='title'>HOME RENOVATIONS WITH NO FUSS</h5>
                        <div className='pb-4 pt-2'>
                            <i  className='bi bi-calendar3 text-warning'></i>
                            <span className='ps-2'>DECEMBER 12 ,2023</span>
                            <span className='ps-4'>
                                <i className='bi bi-chat-dots-fill text-warning'></i>
                                <span className='ps-2'>COMMENTS</span>
                            </span>
                        </div>
                        <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum explicabo, numquam enim dolor quas quo eaque. </div>
                        <h5 className='pt-4 text-warning read-more'>READ MORE</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Our_news
