import React from 'react'

const Contact_home = () => {
    return (
        <div >
            <div className=" p-lg-5  contact__ bg-light">
                <div className="contact1__">
                    <img className="contact1__img rounded" src="image/contact1-min.jpg" />
                </div>
                <div className="row gy-md-4 gy-3 contact2__  p-lg-5 row-gap-1 bg-light" >
                    <p className="fs-1" style={{ fontFamily: "'Roboto', sans-serif" }}> Contact us </p>
                    <div>
                        <label for="exampleFormControlInput1" style={{ fontFamily: "'Roboto', sans-serif" }} class="form-label">Name</label>
                        <input name="name" type="text" style={{ fontFamily: "'Roboto', sans-serif" }} class="form-control" id="exampleFormControlInput1" />
                    </div>
                    <div>
                        <label for="exampleFormControlInput1" style={{ fontFamily: "'Roboto', sans-serif" }} class="form-label">Email address</label>
                        <input type="email" class="form-control" style={{ fontFamily: "'Roboto', sans-serif" }} id="exampleFormControlInput1" placeholder="" />
                    </div>
                    <div>
                        <label for="exampleFormControlInput1" style={{ fontFamily: "'Roboto', sans-serif" }} class="form-label">Subject</label>
                        <input name="subject" type="text" style={{ fontFamily: "'Roboto', sans-serif" }} class="form-control" id="exampleFormControlInput1" />
                    </div>
                    <div>
                        <label for="exampleFormControlTextarea1" style={{ fontFamily: "'Roboto', sans-serif" }} class="form-label">Message</label>
                        <textarea class="form-control" style={{ fontFamily: "'Roboto', sans-serif" }} name="message" id="message" rows="3"></textarea>
                    </div>


                    <div className="col-sm-12">
                        <button type="submit" style={{ fontFamily: "'Roboto', sans-serif", background: ' linear-gradient(90deg, rgba(81,85,251,1) 24%, rgba(25,209,253,1) 70%, rgba(220,187,247,1) 100%)', borderStyle: 'none' }} className="btn btn--base outline mb-2 text-light">
                            Send Your Message<i className="fas fa-paper-plane"></i>
                            <span style={{ top: "249px", left: "75.9844px" }}></span>
                        </button>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact_home
