import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import './About.css'
import About2 from './About2'
import Header from '../header/Header'
import About3 from './About3'
const About = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, []);
    return (
        <div>
            <Header/>
            <div className='text-center header_head' style={{ backgroundColor: '#d9f2f7' }}>
                <div className='fs-4' style={{ fontWeight: "700", fontFamily: "'Roboto', sans-serif" }}>About us</div>

                <div className='d-flex justify-content-center'>
                    <NavLink to='/' style={{ fontFamily: "'Roboto', sans-serif" }} className='text-decoration-none text-dark'><span className='bi bi-house-door-fill text-info'></span> Home</NavLink>
                    <div style={{ fontFamily: "'Roboto', sans-serif" }}><span className='bi bi-arrow-right-short text-info ps-1'></span>About us</div>
                </div>
            </div>
            <About3/>
            <div >
                <div className='bg-light about-data' style={{ paddingTop: "6rem" }}>
                    <div className='img-'>
                        <img className='about-img1' src="image/about1.jpg" alt="" />
                    </div>
                    <div className='about-data-2'>
                        <div  className='about-data-child'>
                            <img src="image/ourNews2.jpg" alt="" />
                            <div className=' p-3 '>
                                <h6 className='title'>FROM IDEA TO REALISATION</h6>
                               
                                <div className='text1'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum explicabo, numquam enim dolor quas quo eaque. </div>
                                
                            </div>
                        </div>
                        <div className='about-data-child'>
                            <img src="image/ourNews1.jpg" alt="" />
                            <div className=' p-3'>
                                <h6 className='title'>MAKE YOUR HOME YOUR PARADISE</h6>
                              
                                <div className='text1'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum explicabo, numquam enim dolor quas quo eaque. </div>
                            </div>
                        </div>
                        <div className='about-data-child'>
                            <img src="image/ourNews3.jpg" alt="" />
                            <div className=' p-3'>
                                <h6 className='title'>HOME RENOVATIONS WITH NO FUSS</h6>
                              
                                <div className='text1'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum explicabo, numquam enim dolor quas quo eaque. </div>
                           </div>
                        </div>
                        <div className='about-data-child'>
                            <img src="image/ourNews3.jpg" alt="" />
                            <div className=' p-3'>
                                <h6 className='title'>HOME RENOVATIONS WITH NO FUSS</h6>
                              
                                <div className='text1'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum explicabo, numquam enim dolor quas quo eaque. </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div>
                <About2/>
            </div>
        </div>
    )
}

export default About
