import React, { useEffect, useState } from 'react';
import './Header.css'; // Import the CSS file
import { NavLink } from 'react-router-dom';
const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 290);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <header className='z-2 position-relative  w-100 top-0'>
      <div className="container preheader-wrap p-4 top-header">
        <div className="site-logo">
          <a>
            <img width={"200px"}
              className="logo-sticky"
              alt="Site logo"
              src="https://construction2.indoredigital.com/images/IDLogo.png"
            />
          </a>
        </div>
        <div className="large-above-menu large-above-menu-style-5">
          <div id="anpstext-10" className="widget widget_anpstext">
            <span className="anpstext-arrow" style={{ color: '#736200' }}></span>
            <span className="fa fa-home p-3 shadow bg-warning" style={{ color: '#736200' }}></span>
            <span className="anpstext-desc ps-2 " style={{ color: '#848484' }}>
              <span className="important" style={{ color: '#000000' }}>112 Your Address 23</span>

              Washington DC 1234
            </span>
          </div>
          <div id="anpstext-9" className="widget widget_anpstext">
            <span className="anpstext-arrow" style={{ color: '#736200' }}></span>
            <span className="fa fa-phone p-3 shadow bg-warning" style={{ color: '#736200' }}></span>
            <span className="anpstext-desc ps-2" style={{ color: '#848484' }}>
              <span className="important" style={{ color: '#000000' }}>Call us anytime</span>

              415 555 1234
            </span>
          </div>
          <div id="anpstext-11" className="widget widget_anpstext">
            <span className="anpstext-arrow" style={{ color: '#736200' }}></span>
            <span className="fa-solid fa-envelope p-3 shadow bg-warning" style={{ color: '#736200' }}></span>
            <span className="anpstext-desc ps-2" style={{ color: '#848484' }}>
              <span className="important" style={{ color: '#000000' }}>Send us a mail</span>

              mail@domain.com
            </span>
          </div>
        </div>
      </div>
      <div className={`site-header ${isSticky ? 'site-header-sticky' : ''} site-header-style-boxed site-header-layout-normal site-header-dropdown-3 header-wrap  border-white m-auto`} style={{ color: "#546e7a", border: ".04 solid #546e7a" }}>
        <div className="container all-container-section  pt-2 pb-2 " >
          <div className='top-logo'>
            <img width={"200px"} className="logo-sticky " alt="Site logo" src="image/White-Indore-Digital-Logo2.png" />
          </div>
          <div className="nav-bar-wrapper ">
            <div className="nav-bar ">
              <nav className={`site-navigation ${isNavOpen ? 'open' : ''}`}>
                <ul id="menu-main-menu" className="">
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5 current_page_item current-menu-ancestor current-menu-parent current_page_parent current_page_ancestor menu-item-has-children menu-item-depth-0">
                    <NavLink to="/">Home</NavLink>

                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-depth-0">
                    <NavLink to='/project'>Projects</NavLink>
                    <ul className="sub-menu" >
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-depth-1">
                        <NavLink to='/project'>MODERN VIEW</NavLink>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-depth-1">
                        <NavLink to='/project'>STANDARD VIEW</NavLink>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-depth-1">
                        <NavLink to='/project'>CLASSIC VIEW</NavLink>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-depth-1">
                        <NavLink to='/project'>RANDOM VIEW</NavLink>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-depth-1">
                        <NavLink to='/project'>FULL WIDTH VIEW</NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-depth-0">
                    <NavLink to='/what-we-do'>What we do</NavLink>
                    <ul className="sub-menu" >
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-depth-1">
                        <NavLink to='/what-we-do'>CONSTRUCTION</NavLink>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-depth-1">
                        <NavLink to='/what-we-do'>RENOVATIONS</NavLink>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-depth-1">
                        <NavLink to='/what-we-do'>CONSULTING</NavLink>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-depth-1">
                        <NavLink to='/what-we-do'>BIG PROJECTS</NavLink>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-depth-1">
                        <NavLink to='/what-we-do'>ARCHITECTURE</NavLink>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-depth-1">
                        <NavLink to='/what-we-do'>CONCRETE TRANSPORT</NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-depth-0">
                    <NavLink to='/about'>About us</NavLink>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-depth-0">
                    <NavLink to='/blog'>Blog</NavLink>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-depth-0">
                    <NavLink to='/shop'>Shop</NavLink>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-depth-0">
                    <NavLink to="/contact-us" >Contact us</NavLink>
                  </li>
                </ul>
              </nav>
              <div className="site-search-toggle">
                <button className="fa fa-search text-light"><span className="sr-only">Search</span></button>
                <div className="site-search-minimal">
                  <form role="search" method="get" className="site-search-minimal__form" action="">
                    <input name="s" type="text" className="site-search-minimal__input" placeholder="type and press ‘enter’" />
                  </form>
                </div>
              </div>
              <button className="navbar-toggle  text-warning ps-4" type="button" onClick={handleNavToggle}>
                <span className="sr-only">Toggle navigation</span>
                <i className="fa fa-bars" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

    </header>
  );
};

export default Header;
